import React from "react";
import Layout from "../layout";
import styled from "styled-components";
import SEO from "../components/seo";
import {
  navyBlue,
  mainRed,
  aku,
  innerWidth,
  transHover,
  gotham,
  gothamBold,
  screen,
} from "../components/common/variables";
import Arrow from "../images/svg/right-arrow.svg";

const Wrapper = styled.div`
  padding-top: 124px;
  padding-bottom: 120px;
  max-width: ${innerWidth};
  margin: 0 auto;
  padding-left: 25px;
  padding-right: 25px;
  @media ${screen.small} {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 135px;
  }
  @media ${screen.medium} {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 150px;
  }
  @media ${screen.large} {
    padding-top: 200px;
  }
  @media ${screen.xLarge} {
    padding-left: 0;
    padding-right: 0;
  }

  .heading {
    color: ${navyBlue};
    font-family: ${aku};
    font-size: 2.1rem;
    @media ${screen.small} {
      font-size: 2.7rem;
    }

    svg {
      width: 14px;
      margin-right: 8px;
      vertical-align: middle;
      @media ${screen.small} {
        width: 19px;
        margin-right: 14px;
      }
      @media ${screen.medium} {
        width: 24px;
      }

      .body {
        fill: ${mainRed};
        transition: ${transHover};
      }
    }
  }

  .bold-description {
    color: ${navyBlue};
    font-family: ${gothamBold};
    margin-top: 20px;
    font-size: 1.1rem;
    max-width: 950px;
    @media ${screen.small} {
      font-size: 1.2rem;
      margin-top: 30px;
    }
  }

  .subheading {
    color: ${navyBlue};
    font-family: ${gotham};
    font-size: 1.35rem;
    margin-top: 35px;
    @media ${screen.small} {
      font-size: 1.5rem;
      margin-top: 45px;
    }
  }

  .content {
    max-width: 1000px;

    &__heading {
      color: ${navyBlue};
      font-family: ${gothamBold};
      font-size: 1.1rem;
      margin-top: 20px;
      @media ${screen.small} {
        font-size: 1.2rem;
        margin-top: 30px;
      }
    }

    &__description {
      color: ${navyBlue};
      font-family: ${gotham};
      font-size: 1rem;
      margin-top: 7px;
      margin-left: 10px;
      margin-bottom: 25px;
      display: flex;
      align-items: flex-start;
      @media ${screen.small} {
        font-size: 1.1rem;
        margin-left: 35px;
        margin-top: 10px;
      }

      .arrow {
        display: block;

        svg {
          width: 8px;
          margin-right: 10px;
          margin-bottom: 1px;
          @media ${screen.small} {
            width: 10px;
            margin-right: 18px;
            margin-bottom: 0;
          }

          .body {
            fill: ${navyBlue};
          }
        }
      }
    }
  }
`;

const TermsPage = () => {
  return (
    <Layout>
      <SEO title="Terms & Conditions | TEN ALPHAS" />
      <Wrapper>
        <h2 className="heading">
          <Arrow />
          Terms & Conditions
        </h2>
        <p className="bold-description">
          If you appoint TEN ALPHAS to deliver work to you for a project or
          ongoing basis, you are bound by the following Terms & Conditions.
        </p>
        <h4 className="subheading">Definitions</h4>
        <main className="content">
          <h5 className="content__heading">The Client</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            The Client: Also referred to as ‘You’, the company, business or
            client, authorised representative of the client, or individual
            requesting the services of TEN ALPHAS.
          </p>
          <h5 className="content__heading">TEN ALPHAS</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Also referred to as ‘I’, ‘Our’ and ‘We’, the production company &
            employees or affiliates.
          </p>
          <h5 className="content__heading">The Project</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS will issue you with a proposal via email outlining our
            interpretation of your brief, the scope of works, work method,
            deliverables, limitations of service, and pricing.
          </p>
          <h5 className="content__heading">1. Acceptance</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Acceptance of the proposal TEN ALPHAS provides you with is deemed
            acknowledgement and agreement to the the following terms and
            conditions.
          </p>

          <h5 className="content__heading">2. Payment</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS accepts payment via direct bank deposit. Invoices will be
            sent periodically, relating to work done to that date. The client
            agrees to settle accounts within 14 (fourteen) days of receipt of
            invoice. Exceptional payment timelines can be arranged, depending on
            the payment policies of the client. TEN ALPHAS will be under no
            obligation to proceed to an ensuing stage of the project until all
            outstanding invoices have been met in full.
          </p>
          <h5 className="content__heading">3. Confidentiality</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS may ask you for details in order to fulfill the
            requirements of the Project. TEN ALPHAS undertake not to misuse any
            confidential information that may be made available to us. The
            client agrees not to disclose the fees charged by TEN ALPHAS. The
            Client is responsible for keeping confidential, any passwords and
            usernames issued to them by TEN ALPHAS.
          </p>
          <h5 className="content__heading">4. Exclusivity</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS do not work on an exclusive basis unless this has been
            specifically negotiated.
          </p>
          <h5 className="content__heading">5. Deposit</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            A 50% deposit of the total fee payable under our proposal for The
            Project is due before work will commence. At the discretion of TEN
            ALPHAS, the remaining amount will be due in accordance with the
            project stages outlined in your proposal. We reserve the right not
            to commence any work until the deposit has been paid in full.
          </p>
          <h5 className="content__heading">
            6. Modifications & Additional/Optional/Recommended Services
          </h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Any modification or alteration which increases the time to complete
            a Project (beyond the proposed and stated deliverables) may result
            in supplementary charges. You will be notified when moving into
            additional production time, and you will be sent an estimate for you
            to approve.
          </p>
          <h5 className="content__heading">
            7. Disbursements & Out Of Pocket Expenses
          </h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Fees for TEN ALPHAS’ professional services do not include outside
            purchases such as transportation, accommodation, catering,
            specialist equipment hire. These items will not be accrued without
            your prior knowledge. Anticipated disbursements will be itemised in
            the Proposal.
          </p>
          <h5 className="content__heading">8. Time</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Fees estimated in this proposal are done so for the purpose of
            budgeting. The costs are generated from experience based on similar
            projects. Fees are subject to change. Should there be any additions
            or alterations to the brief, TEN ALPHAS will keep the client
            informed of both work and fee status during the production process.
            If the Client does not provide expected information or respond to
            TEN ALPHAS in a reasonable amount of time, the timelines for the
            project may be affected. TEN ALPHAS will not be liable for any loss
            or damage resulting from such estimated time periods not being met.
          </p>
          <h5 className="content__heading">9. Consultation</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Where required, milestone meetings/calls/video hangout sessions will
            be costed into each stage as itemised in the Proposal. Should
            additional meetings be requested or special presentations required,
            these will be charged at the hourly rate and reflected on the
            invoice. You will be notified if a meeting or call is in excess of
            the anticipated project scope.
          </p>
          <h5 className="content__heading">
            10. Project Delays And Client Responsibility
          </h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS will let you know of any required
            information/copy/imagery/services etc upon the project Proposal
            being accepted. Any time frames or estimates that we give are
            reliant upon you providing TEN ALPHAS with accurate and timely
            information and materials. During post-production there is a certain
            amount of feedback required in order to progress to subsequent
            phases. It is required that a single point of contact be appointed
            from your side and be made available on a reliable basis in order to
            expedite the feedback process. You must supply all materials and
            information required by us to complete the work in accordance with
            any agreed specification. Such materials may include, but are not
            limited to, photographs, written copy and logos. Where there is any
            delay in supplying these materials to us which leads to a delay in
            the completion of work, we have the right to extend any previously
            agreed deadlines by a reasonable amount.
          </p>
          <h5 className="content__heading">11. Communication</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Once the Project has commenced, you must be available via e-mail,
            able to answer all questions and complete tasks throughout the
            production process.
          </p>
          <h5 className="content__heading">
            12. Direction, Feedback And Approval
          </h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            The Client agrees to exercise due diligence in its direction to us
            regarding the pre-production, production and post-production phases.
            The script, creative treatment, locations and interviewees must be
            signed off during pre-production. If a client representative is
            present during the shoot, they should approve all shots, and, where
            appropriate, may request extra content ad-hoc. During
            post-production, the client is responsible for providing feedback in
            a timely and clear manner, and have all necessary stakeholders and
            the company’s legal department sign off on the videos prior to final
            delivery. TEN ALPHAS will not be held liable for any legal
            infringements as a result of video content being distributed or
            published under client direction and is not liable for errors or
            omissions once final delivery has been signed.
          </p>
          <h5 className="content__heading">
            13. Motion Graphics Package And Training
          </h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS can assemble a motion graphics After Effects package for
            your company, and train an appropriate company member with Adobe
            Premiere Pro skills how to apply these graphics to videos. This
            service is available at an additional cost.
          </p>
          <h5 className="content__heading">14. Distribution Platform</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS will provide video content in a format/quality suitable
            for your specified distribution platforms (online, TV, etc). If
            requested, we can also deliver all footage and Master Files on a
            drive supplied by the client. Please specify if you require this
            service in your initial Brief. There may be additional charged if
            this is requested at a later stage.
          </p>
          <h5 className="content__heading">
            15. Property & Suppliers Performance
          </h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS will take on all responsibility for the performance of
            our equipment, employees and suppliers. If you select your own
            suppliers, other than those recommended by us, to carry out certain
            aspects of a Project we cannot in anyway be held responsible for
            quality, performance or delivery.
          </p>
          <h5 className="content__heading">16. Cancellation</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            Should the project be postponed or cancelled by the client, then the
            client shall be liable for our fees, costs and expenses up to the
            time TEN ALPHAS are notified in writing of such cancellation.
          </p>
          <h5 className="content__heading">17. Liability</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS are covered for Public Liability with an indemnity of
            $20m with Cinesure Entertainment Risk Specialists.
          </p>
          <h5 className="content__heading">18. Project Ownership</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            TEN ALPHAS has the right to use and distribute all videographic,
            photographic, written or recorded content produced for clients,
            unless confidentiality is clearly requested in the Client’s initial
            brief. TEN ALPHAS is not liable for any legal implications of
            distributing content that has not been clearly specified as private
            and confidential by the Client prior to the completion of the
            Project. The working files remain the property of TEN ALPHAS and can
            be purchased for an additional 25% of the project fee.
          </p>
          <h5 className="content__heading">19. Feedback</h5>
          <p className="content__description">
            <span className="arrow">
              <Arrow />
            </span>
            After the completion of the Project, you may also be asked to write
            a brief testimonial paragraph for the TEN ALPHAS website. You are
            under no obligation to do so but your feedback is always
            appreciated.
          </p>
        </main>
      </Wrapper>
    </Layout>
  );
};

export default TermsPage;
